<template>
  <div>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!--begin::Card-->
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 v-if="$route.meta.mode !== 'update'" class="card-title">
                  Ajouter une publicité
                </h3>
                <h3 v-if="$route.meta.mode === 'update'" class="card-title">
                  Modifier une publicité
                </h3>
              </div>
              <!--begin::Form-->
              <form class="form" @submit.prevent="save">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-md-3">
                      <label>Partenaire</label>
                      <multiselect
                        v-model="form.partenaire"
                        :options="option.parteners"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :hide-selected="true"
                        placeholder="Choisir un type"
                        label="nom_partenaire"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        @input="setPartnerId()"
                        :class="{ 'is-invalid': errors.partenaire_id }"
                      ></multiselect>
                      <span
                        class="form-text text-danger"
                        v-if="errors.partenaire_id"
                        >{{ errors.partenaire_id[0] }}</span
                      >
                    </div>
                    <div class="col-md-3">
                      <label>Status</label>
                      <select class="form-control" v-model="form.state">
                        <option value="1">Actif</option>
                        <option value="0">Inactif</option>
                      </select>
                      <span class="form-text text-danger" v-if="errors.state">{{
                        errors.state[0]
                      }}</span>
                    </div>
                    <div class="col-md-3">
                      <label>Priorité</label>
                      <input
                        type="number"
                        step="0"
                        min="0"
                        class="form-control"
                        v-model="form.priority"
                        :class="{ 'is-invalid': errors.priority }"
                      />
                      <span
                        class="form-text text-danger"
                        v-if="errors.priority"
                        >{{ errors.priority[0] }}</span
                      >
                    </div>
                    <div class="col-md-3">
                      <label>Propriétaire</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.owner"
                        :class="{ 'is-invalid': errors.priority }"
                      />
                      <span class="form-text text-danger" v-if="errors.owner">{{
                        errors.owner[0]
                      }}</span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <!--  <div class="col-md-3">
                      <label>Type de pub</label>
                      <select
                        class="form-control"
                        v-model="form.type_pub"
                        id="type_web"
                      >
                        <option value="web">Web</option>
                        <option value="mobile">Mobile</option>
                      </select>
                      <span
                        class="form-text text-danger"
                        v-if="errors.type_pub"
                        >{{ errors.type_pub[0] }}</span
                      >
                    </div> -->
                    <div class="col-md-3"></div>
                    <div class="col-md-4">
                      <label for="fileInput" slot="upload-label">
                        <span class="upload-caption">{{
                          hasImage ? "Remplacer" : "Téléverser"
                        }}</span>
                      </label>
                      <image-uploader
                        :preview="true"
                        :maxWidth="512"
                        doNotResize="['gif', 'svg']"
                        :className="[
                          'fileinput',
                          'form-control',
                          { 'fileinput--loaded': hasImage }
                        ]"
                        capture="environment"
                        :debug="0"
                        :autoRotate="true"
                        output-format="blob"
                        @input="setImage"
                      ></image-uploader>
                      <span
                        class="form-text text-danger"
                        v-if="errors.publicite_cover_image"
                        >{{ errors.publicite_cover_image[0] }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="card-footer border-2">
                  <div class="row">
                    <div class="col-lg-12 text-right">
                      <button
                        type="submit"
                        ref="kt_login_signin_submit"
                        class="btn btn-outline-primary btn-primary mr-2"
                      >
                        Enregistrer
                      </button>
                      <button
                        type="reset"
                        class="btn btn-outline-secondary btn-secondary"
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
            <!--end::Card-->
          </div>
        </div>
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import ImageUploader from "vue-image-upload-resize";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
export default {
  name: "Form",
  components: {
    Multiselect,
    ImageUploader
  },
  data() {
    return {
      initialize: "publicite/create",
      store: "publicite",
      method: "post",
      form: {},
      option: {
        parteners: []
      },
      hasImage: false,
      photo: [],
      images: [],
      success: false,
      has_error: false,
      error: "",
      errors: {},
      isValidData: null
    };
  },
  beforeMount() {
    if (this.$route.meta.mode === "update") {
      this.initialize = "publicite/" + this.$route.params.id + "/edit";
      this.store = "publicite/" + this.$route.params.id;
      this.method = "post";
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.initialize).then(response => {
        vm.form = response.data.form;
        response.data.option
          ? (vm.option = response.data.option)
          : (vm.option = response.data.options);
      });
    },
    setImage: function(output) {
      this.hasImage = true;
      this.photo = output;
    },
    setPartnerId() {
      this.form.partenaire_id = this.form.partenaire.id;
    },

    save() {
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
      let vm = this;
      let formData = new FormData();
      let config = { headers: { "content-type": "multipart/form-data" } };

      Object.entries(vm.form).forEach(([key, value]) => {
        formData.set(key, value);
      });
      formData.append("publicite_cover_image", this.photo);
      if (this.$route.meta.mode === "update") {
        formData.append("_method", "PUT");
      }

      ApiService[this.method](this.store, formData, config)
        .then(function(response) {
          if (response.data.success) {
            Swal.fire({
              title: "Success",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              showClass: {
                popup: "animate__animated animate__fadeInDown"
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp"
              }
            });
            vm.$router.push(vm.$route.meta.back);
          }
        })
        .catch(function(error) {
          vm.errors = error.response.data.errors;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right",
            "disabled"
          );
        });
    }
  }
};
</script>

<style scoped>
.img-preview {
  width: 100%;
  height: auto;
}
</style>
